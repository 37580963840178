export const treePositions = [
    [ 5, 0, - 39 ],
    [ 0, 0, - 48 ],
    [ 10, 0, - 51 ],
    [ 13, 0, - 29 ],
    [ - 13, 0, - 40 ],
    [ - 5, 0, - 40 ],
    [ - 20, 0, - 40 ],
    [ - 25, 0, - 40 ],
    [ - 40, 0, - 40 ],
    [ - 34, 0, - 38 ],
    [ - 48, 0, - 21 ],
    [ - 51, 0, - 30 ],
    [ - 51, 0, - 39 ],
    [ - 48, 0, - 34 ],
    [ 30, 0, - 29 ],
    [ 25, 0, - 29 ],
    [ 22, 0, - 35 ],
    [ 50, 0, - 21 ],
    [ 40, 0, - 27 ],
    [ 45, 0, - 23 ],
    [ 42, 0, - 29 ],
    [ 60, 0, - 10 ],
    [ 55, 0, - 14 ],
]